import React, { useContext, useEffect, useRef, useState } from 'react';
import { CountryContext } from '../../AddressContainer';
import type { CountryContext as CountryContextType } from '../../AddressContainer';
import type {
  FieldComponentProps,
  Field,
  FieldsDeepUpdate,
} from '../../../typescript/types/FieldComponentProps';
import { InputAdornment, TextField, TextFieldVariants } from '@mui/material';
import textFieldStyles from '../../../../../stylesheets/components/textField.module.scss';
import { formatPhoneNumber } from './PhoneInput';
import { formatErrorMessages, replaceZipWithPostal } from '../../Tools';
import { FIELD_MODES } from '../FieldTools';
import { NumericFormat } from 'react-number-format';

export interface TextField extends Field {
  label: string;
  value: string | number | null | undefined;
  type?: string; // can be any HTML input type or 'currency'/'percentage'
  disabled?: boolean;
  required?: boolean;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  autocomplete?: string;
  decimalScale?: number;
  autoFocus?: boolean;
}

export interface TextProps extends FieldComponentProps {
  field: TextField;
  onChange: FieldsDeepUpdate;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  variant?: TextFieldVariants | undefined;
  editClassNames?: string;
  displayClassNames?: string;
}

export interface TextFieldGlimmerProps {
  editClassNames?: string;
  glimmerClassNames?: string;
}

const Text = ({
  field,
  mode,
  onChange,
  onKeyDown,
  variant = 'outlined',
  editClassNames,
  displayClassNames,
  testClass,
  errors = [],
}: TextProps) => {
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement | null>(null);
  const isMounted = useRef(false);
  const previousPhoneNumberRef = useRef<string | null>(null);
  const lastKeyDown = useRef<string | null>(null);
  const { country }: CountryContextType = useContext(CountryContext);
  const formattedErrorMessages = formatErrorMessages(errors, field.label);

  useEffect(() => {
    const input = ref.current;
    if (input && field.type === 'tel' && document.activeElement === input) {
      input.setSelectionRange(cursor, cursor);
    }
  });

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      if (errors.length > 0) {
        if (ref.current) {
          ref.current.focus();
        }
      }
    }
  }, [errors]);

  const handleKeyDown = onKeyDown
    ? onKeyDown
    : (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (field.type === 'tel') {
          lastKeyDown.current = e.code;
        }
      };

  const handleValueChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | React.SyntheticEvent<HTMLInputElement, Event>
  ) => {
    if (!('value' in e.target && 'name' in e.target)) return;
    const { name, value } = e.target;
    setCursor(e.target.selectionStart);
    if (field.type === 'currency' || field.type === 'percentage') {
      onChange(name, parseFloat(value));
    } else if (field.type === 'tel') {
      const { formattedPhoneNumber, newCursorPosition } = formatPhoneNumber(
        e.target.value,
        previousPhoneNumberRef.current,
        e.target.selectionStart || e.target.value.length - 1,
        lastKeyDown.current
      );
      onChange(field.name, formattedPhoneNumber);
      setCursor(newCursorPosition);
      previousPhoneNumberRef.current = formattedPhoneNumber;
    } else {
      onChange(name, value);
    }
  };

  const maxLength = field.type === 'tel' ? 14 : field.maxLength;
  let htmlType = field.type;
  if (field.type === 'currency' || field.type === 'percentage') {
    htmlType = 'number';
  }

  const InputProps = {
    pattern: field.pattern,
    minLength: field.minLength,
    maxLength: maxLength,
    startAdornment: null as JSX.Element | null,
    endAdornment: null as JSX.Element | null,
  };
  if (field.type === 'currency') {
    InputProps.startAdornment = (
      <InputAdornment position="start">$</InputAdornment>
    );
  } else if (field.type === 'percentage') {
    InputProps.endAdornment = <InputAdornment position="end">%</InputAdornment>;
  }

  if (mode === FIELD_MODES.EDIT) {
    if (field.type === 'currency' || field.type === 'percentage') {
      return (
        <div className={editClassNames} data-test-class={testClass}>
          <NumericFormat
            error={errors.length > 0}
            data-test-class={field.type}
            value={field.value}
            customInput={TextField}
            name={field.name}
            decimalScale={
              field.decimalScale !== undefined ? field.decimalScale : 2
            }
            onValueChange={(values, sourceInfo) => {
              if (sourceInfo && sourceInfo.event)
                handleValueChange(sourceInfo.event);
            }}
            InputProps={InputProps}
            fixedDecimalScale
            required={field.required}
            disabled={field.disabled}
            placeholder={field.placeholder}
            autoComplete={field.autocomplete}
            label={field.label}
            variant={variant}
            helperText={formattedErrorMessages}
            autoFocus={field.autoFocus}
          />
        </div>
      );
    }
    return (
      <div className={editClassNames} data-test-class={testClass}>
        <TextField
          error={errors.length > 0}
          data-test-class={field.type}
          label={replaceZipWithPostal(field.label, country)}
          inputRef={ref}
          value={field.value || ''}
          name={field.name}
          onChange={(e) => handleValueChange(e)}
          onKeyDown={handleKeyDown}
          type={htmlType}
          disabled={field.disabled}
          required={field.required}
          InputProps={InputProps}
          variant={variant}
          placeholder={field.placeholder}
          autoComplete={field.autocomplete}
          className={textFieldStyles.textField}
          helperText={formattedErrorMessages}
          autoFocus={field.autoFocus}
        />
      </div>
    );
  } else {
    return (
      <div className={displayClassNames}>
        <span className="label">
          {replaceZipWithPostal(field.label, country)}
        </span>
        <span className="value">{field.value}</span>
      </div>
    );
  }
};

export const TextFieldGlimmer = ({
  editClassNames,
  glimmerClassNames,
}: TextFieldGlimmerProps) => {
  return (
    <div className={editClassNames}>
      <label className={glimmerClassNames} />
    </div>
  );
};

export default Text;
